import { createApp, h } from 'vue'
import { InertiaProgress } from '@inertiajs/progress'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { Inertia } from '@inertiajs/inertia'
import '@/Helpers/prototypes'
import '../css/app.css';
import { Userpilot } from 'userpilot'
import * as Sentry from "@sentry/vue";
import DOMPurify from 'dompurify';
import { registerLicense } from '@syncfusion/ej2-base'

InertiaProgress.init()

createInertiaApp({

    //resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', { eager: false })),

    title: title => title ? `${title} - WorkflowMax` : 'WorkflowMax',
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .mixin({
                mounted() {
                    if (typeof Userpilot !== 'undefined') {
                        Userpilot.initialize("NX-682a9454");
                    } else {
                        console.error('Userpilot library not found.');
                    }

                    this.popStateHandler = event => {
                        Inertia.reload();
                    };
                    //window.addEventListener('popstate', this.popStateHandler);

                },
                beforeUnmount() {
                    //window.removeEventListener('popstate', this.popStateHandler);
                },
                methods: { route }
            });

        // Add DOMPurify as a global property for sanitizing content
        app.config.globalProperties.$sanitize = (dirty) => {
            return DOMPurify.sanitize(dirty);
        };

        Sentry.init({
            app,
            dsn: import.meta.env?.VITE_SENTRY_DSN ?? "",
            environment: import.meta.env?.VITE_SENTRY_ENV ?? 'dev',
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            enabled: import.meta.env?.VITE_SENTRY_DSN != ""
        });

        registerLicense(import.meta.env?.VITE_SYNCFUSION_LICENSE_KEY)

        app.mount(el)

        app.config.errorHandler = (err, vm, info) => {
            // Handle errors appropriately
            console.error('Global Exception Handler:', err, info);
        };

        // window.addEventListener('popstate', function (event) {
        //     Inertia.reload()
        // });
    },

})
